<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12">
        <data-table
          :headers="headers"
          :items="obCollection.getModelList()"
          :options="pagination"
          :total="serverItemsLength"
          :last-page="serverLastPage"
          :loading="bLocalLoading"
          btn-action-path="brands"
          btn-action-item-key-id="id"
          hide-btn-action-view
          @delete="deleteItem"
          @update:sort-by="onSortBy"
          @update:sort-desc="onSortDesc"
          @update:options="onPagination"
          @update:page="onPage"
        >
          <template v-slot:[`item.active`]="{ item }">
            <active-icon :active="item.active" />
          </template>

          <template #[`item.actions`]="{ item }">
            <module-actions :value="item.id" @delete="deleteItem" />
          </template>
        </data-table>
      </v-col>
    </v-row>
    <brands-form
      v-model="obItem"
      :open.sync="displayForm"
      :key="$route.params.id"
    />
  </v-col>
</template>

<script lang="ts">
import { Mixins, Component } from "vue-property-decorator";
import BrandsMixin from "@/modules/brands/mixins/BrandsMixin";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import BrandsForm from "@/modules/brands/components/Form.vue";

@Component({
  components: {
    ActiveIcon,
    BrandsForm,
  },
})
export default class BrandsList extends Mixins(BrandsMixin) {
  isList = true;

  onMounted() {
    this.index();
  }
}
</script>
